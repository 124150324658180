import styled from "styled-components"
import DesignTokens from "./designTokens"

const Container = styled.div`
  margin: 0 1rem;
  @media screen and (min-width: 769px) and (max-width: 1024px) {
    margin: 0 2rem;
    max-width: 960px;
  }
  @media screen and (min-width: 1025px) and (max-width: 1420px) {
    margin: 0 2rem;
    max-width: 1378px;
  }
  @media screen and (min-width: 1421px) {
    margin: 0 auto;
    max-width: 1378px;
  }
`

const ItemsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    margin: 0;
  }
`

const IsDesktop = styled.div`
  display: none;
  @media screen and (min-width: 769px) {
    display: block;
  }
`

const IsMobile = styled.div`
  display: block;
  @media screen and (min-width: 769px) {
    display: none;
  }
`

const CustomLink = styled.a`
  color: ${DesignTokens.colors.primary[500]};
  &:hover {
    color: ${DesignTokens.colors.tertiary[500]};
  }
`

export { Container, ItemsList, CustomLink, IsDesktop, IsMobile }
