import { useStaticQuery, graphql } from "gatsby"

export const useFooter = () => {
  const footer = useStaticQuery(graphql`
    query FooterQuery {
      datoCmsFooter {
        id
        content {
          partners {
            whiteLogo {
              alt
              url
            }
            name
            id
            url
          }
          section
        }
      }
    }
  `)
  return footer["datoCmsFooter"]
}
