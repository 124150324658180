import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import DesignTokens from "./designTokens"

const InnerNav = () => {
  return (
    <Wrapper>
      <Link to="/">⟵ Torna alla home</Link>
    </Wrapper>
  )
}

export default InnerNav

const Wrapper = styled.nav`
  padding: ${DesignTokens.spacing[7]};
  background: ${DesignTokens.colors.white};
  box-shadow: ${DesignTokens.shadows.floating};
  width: 100%;
  z-index: 1;
  /* box-shadow: ${DesignTokens.shadows.floating}; */
  a {
    cursor: pointer;
    font-weight: 700;
  }
  a:hover, a.active {
    color: ${DesignTokens.colors.primary[500]};
  }
`
