import React from "react"
import Helmet from "react-helmet"
import Footer from "./footer"

const Layout = ({ children, locale }) => {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {children}
      <Footer />
    </>
  )
}

export default Layout