import React from "react"
import styled from "styled-components"
import { useFooter } from "../../../hooks/useFooter"
import { Link } from "gatsby"
import { ItemsList } from "./utils"
import { Container, Row, Col } from "react-grid-system"
import DesignTokens from "./designTokens"
import graffa from "../../../images/graffa.png"
import { Lead, BodySmall } from "./typography"
import { OutboundLink } from "../../home/link"

const Footer = () => {
  const footer = useFooter()
  return (
    <Wrapper>
      <Container>
        <Row>
          {footer.content.map((block, index) => (
            <LogoBlock block={block} index={index} />
          ))}
        </Row>
        <Row justify="between">
          <Col md={12}>
            <LinkGroup>
              <li>
                <Link to="/privacy-policy">
                  <BodySmall>. Privacy Policy</BodySmall>
                </Link>
              </li>
              <li>
                <Link to="/cookie-policy">
                  <BodySmall>. Cookie Policy</BodySmall>
                </Link>
              </li>
              <li>
                <OutboundLink
                  href="mailto:info@dimoredesign.it"
                  target="blank"
                  rel="noopener nofollow"
                >
                  <BodySmall>. info@dimoredesign.it</BodySmall>
                </OutboundLink>
              </li>
              <li>
                <OutboundLink
                  href="https://multi-consult.it"
                  target="blank"
                  rel="noopener nofollow"
                >
                  <BodySmall>. Credits</BodySmall>
                </OutboundLink>
              </li>
              <li>
                <OutboundLink
                  href="https://archivio.dimoredesign.it"
                  target="blank"
                  rel="noopener nofollow"
                >
                  <BodySmall>. Edizioni passate</BodySmall>
                </OutboundLink>
              </li>
            </LinkGroup>
          </Col>
        </Row>
      </Container>
    </Wrapper>
  )
}

const LogoBlock = ({ block, index }) => (
  <Block md={index === 3 ? 12 : 4} key={block.id}>
    <BodySmall weight={"700"}>{block.section}</BodySmall>
    <Images>
      {block.partners.map(partner => (
        <OutboundLink href={partner.url} target="blank" rel="nofollow noopener">
          <img src={partner.whiteLogo.url} alt={partner.whiteLogo.alt} key={partner.id} />
        </OutboundLink>
      ))}
    </Images>
  </Block>
)

const Block = styled(Col)`
  & > * {
    margin-bottom: ${DesignTokens.spacing[7]};
  }
`

const Images = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  a:hover {
    opacity: 0.8;
  }
  img {
    min-height: 2rem;
    max-height: 2rem;
  }
  & > * {
    margin-right: ${DesignTokens.spacing[4]};
    &:last-child {
      margin-right: 0;
    }
  }
`

const LinkGroup = styled(ItemsList)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 3rem;
  flex-wrap: wrap;
  & > li {
    margin-right: ${DesignTokens.spacing[4]};
    &:last-child {
      margin-right: 0;
    }
    a {
      color: ${DesignTokens.colors.white};
      text-decoration: none;
      &:hover {
        color: ${DesignTokens.colors.primary[500]};
      }
    }
  }
`

export default Footer

const Wrapper = styled.div`
  background: ${DesignTokens.colors.black};
  background-image: url(${graffa});
  background-repeat: no-repeat;
  background-position: top center;
  color: ${DesignTokens.colors.white};
  padding-top: ${DesignTokens.spacing[11]};
  padding-bottom: ${DesignTokens.spacing[11]};
`
