import React from "react"
import { Container, Row, Col } from "react-grid-system"
import styled from "styled-components"
import Layout from "../components/editions/2020/layout"
import SEO from "../components/editions/2020/seo"
import { Display, HeadingSmall, Body } from "../components/editions/2020/typography"
import InnerNav from "../components/editions/2020/innerNav"

const Success = () => {
  return (
    <Layout>
      <SEO title="Iscrizione confermata" />
      <InnerNav />
      <CustomContainer>
        <Row justify="center">
          <Col md={12} lg={9} xl={9}>
            <Wrapper>
              <Display>Iscrizione<br />confermata</Display>
              <HeadingSmall>Grazie per esserti iscritto!</HeadingSmall>
              <Body>
                Tutti i venerdì riceverai la puntata del progetto "Designers for
                Bergamo". Enjoy!
              </Body>
            </Wrapper>
          </Col>
        </Row>
      </CustomContainer>
    </Layout>
  )
}

const CustomContainer = styled(Container)`
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
  & > * {
    margin-bottom: 3rem;
    &:last-child {
      margin-bottom: none;
    }
  }
`
const Wrapper = styled.div`
  & > * {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: none;
    }
  }
`

export default Success
